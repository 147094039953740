.contact-container {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1.8em;
  }
  
  .contact-form {
    max-width: 500px;
  }
  
  .submit-button {
    font-size: 21px;
    width: auto;
    text-align: center;
    align-self: center;
    background-color: #222831;
    color: #00fff5;
    line-height: 1.4;
    width: 10rem;
    margin: 10px;
    border-color: #00adb5;
    border-radius: 7.5%;
  }

  .submit-button-container {
    display: flex;
    flex-direction: column;
  }
  
  @media screen and (max-width: 480px) {
    .contact-form {
      max-width: 100%;
    }
  }
  