@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
/* Input.css */

.input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Left-aligned */
    max-width: 500px; /* Adjust the maximum width as needed */
    margin: 0 auto; /* Center horizontally */
    padding: 20px; /* Add padding for spacing */
  }
  
  .contact-label {
    font-size: 24px; /* Adjust the label font size */
    color: #2f2f2f;
    font-weight: normal;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  
  .contact-input,
  .contact-checkbox,
  .contact-multiline {
    font-size: 18px; /* Adjust the input font size */
    color: #2f2f2f;
    font-weight: normal;
    line-height: 1.4;
    padding: 10px; /* Add padding for spacing */
    border: 1px solid #ccc; /* Add a border if desired */
    border-radius: 7px; /* Add border-radius if desired */
    margin-bottom: 10px; /* Add margin between inputs */
  }

  .contact-input,
  .contact-multiline {
    width: 100%;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
  }
  
  .contact-checkbox {
    margin-right: 10px; /* Adjust the spacing between checkbox and label text */
  }
  
  .checkbox-label {
    font-size: 18px; /* Adjust the label font size for checkboxes */
  }
  
.contact-container {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1.8em;
  }
  
  .contact-form {
    max-width: 500px;
  }
  
  .submit-button {
    font-size: 21px;
    width: auto;
    text-align: center;
    align-self: center;
    background-color: #222831;
    color: #00fff5;
    line-height: 1.4;
    width: 10rem;
    margin: 10px;
    border-color: #00adb5;
    border-radius: 7.5%;
  }

  .submit-button-container {
    display: flex;
    flex-direction: column;
  }
  
  @media screen and (max-width: 480px) {
    .contact-form {
      max-width: 100%;
    }
  }
  
/* ourService.css */

.service-container {
    display: flex;
    align-items: center;
    margin-bottom: 4em;
    flex-wrap: wrap-reverse;
  }
  
  .service-container.reversed {
    flex-direction: row-reverse;
  }
  
  .service-img {
    width: 22em;
    height: 17em;
  }
  
  @media screen and (max-width: 480px) {
    .service-container {
      justify-content: center;
    }
  
    .service-img {
      width: 18em;
      height: 13em;
    }
  }
  
  .description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60%;
  }
  
  .service-title {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    margin: 5px 0;
  }
  
  .service-description {
    color: #7a7a7a;
    font-size: 21px;
    text-align: center;
    width: 55%;
  }
  
  @media screen and (max-width: 480px) {
    .description-container {
      max-width: 100%;
    }
  }
  
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#error-page {
  text-align: center;
}
/* ServicesSection.css */

.headshot-img {
    width: 30em;
    max-width: 70%; /* Add this line */
    border-radius: 26%;
    margin: 20px 0px;
  
    @media screen and (max-width: 480px) {
      width: 60%;
    }
  }

#about {
    width: 100%;
    min-height: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
  }
  
  #about h2 {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    margin: 5px 0;
  }
  
  #about p {
    color: #7a7a7a;
    font-size: 21px;
    text-align: center;
    max-width: 55%;
  }
  
  .service {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3em;
  }
  
  .service-reversed {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3em;
  }
  
  .service-content {
    flex: 1 1;
    padding: 0 20px;
  }
  
  .service h3 {
    font-size: 24px;
    margin-bottom: 1em;
  }
  
  .service img {
    max-width: 100%;
  }
  
.css-typing p {
    border-right: .15em solid orange;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
  }
  .css-typing p:nth-child(1) {
    width: 100%;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  /* .css-typing p:nth-child(2) {
    width: 11.5em;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .css-typing p:nth-child(3) {
    width: 7.3em;
    opacity: 0;
    -webkit-animation: type3 5s steps(20, end), blink .5s step-end infinite alternate;
    animation: type3 5s steps(20, end), blink .5s step-end infinite alternate;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  } */
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }

  @keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
.App {
  text-align: center;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #00fff5;
}
.container {
    background-size: cover;
    width: 100%;
    height: 100%;
    background-color: #282c34;
    padding-top: 30px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.blog-photos {
    max-width: 250px;
    margin: 10px;
}

.title {
    text-align: center;
    color: #00fff5  ;
}

.header {
    max-width: 800px;
    margin: auto;
    color: white;
}

.padding-left {
    padding-left: 20px;
}

.content {
    max-width: 800px;
    margin: auto;
    background-color: white !important;
    padding: 10px;
    border-radius: 2.5px;
}
