/* ourService.css */

.service-container {
    display: flex;
    align-items: center;
    margin-bottom: 4em;
    flex-wrap: wrap-reverse;
  }
  
  .service-container.reversed {
    flex-direction: row-reverse;
  }
  
  .service-img {
    width: 22em;
    height: 17em;
  }
  
  @media screen and (max-width: 480px) {
    .service-container {
      justify-content: center;
    }
  
    .service-img {
      width: 18em;
      height: 13em;
    }
  }
  
  .description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60%;
  }
  
  .service-title {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    margin: 5px 0;
  }
  
  .service-description {
    color: #7a7a7a;
    font-size: 21px;
    text-align: center;
    width: 55%;
  }
  
  @media screen and (max-width: 480px) {
    .description-container {
      max-width: 100%;
    }
  }
  